<template>
  <div>
    <content-template :menuName="$t('sidebar.changePassword')">
      <template #form>
        <form>
          <Input
            v-model="edited_item.current_password"
            :label="$t('changePassword.currentPassword')"
            :error="validation.current_password"
            class="s-mb-20"
            type="password"
          />
          <Input
            v-model="edited_item.new_password"
            :label="$t('changePassword.newPassword')"
            class="s-mb-20"
            :error="validation.new_password"
            type="password"
          />
          <Input
            v-model="edited_item.confirm_password"
            :label="$t('changePassword.confirmPassword')"
            class="s-mb-20"
            :error="validation.confirm_password"
            type="password"
          />
        </form>

        <div class="s-mt-20 s-flex">
          <div class="s-ml-auto">
            <Button class="s-ml-20" @click="onFinish(edited_item)" :isLoading="isLoading"
              >{{$t('general.submit')}}</Button
            >
          </div>
        </div>
      </template>
    </content-template>
  </div>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import form from "@/mixins/form";
import { mapActions} from "vuex";
import common from "@/mixins/common";

export default {
  components: { ContentTemplate },
  mixins: [form, common],
  props: {
    menuName: String,
    item: Object,
    inputType: String,
    userType: String,
  },
  data() {
    return {
      fileDoc: {},
      edited_item: {
        current_password: "",
        new_password: "",
        confirm_password: "",
      },
      validation: {
        current_password: "",
        new_password: "",
        confirm_password: "",
      },
      //error
      error: false,
      isLoading : false
    };
  },

  computed: {
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
  },

  methods: {
    ...mapActions({
      changePassword: "user/changePassword",
    }),

    async onFinish(data) {
      this.checkEmptyField(data, this.validation, false, null, this.inputType);
      if (!this.empty_field) {
        this.isLoading = true
        if (data.new_password !== data.confirm_password) {
          this.showSnackbar({
            type: "error",
            text: "New Password and Confirm New Password should be the same!",
          });
        } else {
          const data = {
            current_password: this.edited_item.current_password,
            new_password: this.edited_item.new_password,
          };
          await this.changePassword(data);

          const response = this.getResponse("user");
          if (response.status !== 1) {
            this.showSnackbar({
              type: "error",
              text: response.msg,
            });
          } else {
            this.showSnackbar({
              type: "success",
              text: "general.editSuccess",
            });
          }
        }
        this.isLoading = false
      }
    },

    moveToTable(paramName) {(
      this.$router.push(this.$translate({
        name: paramName,
      })));
    },

    cancelForm() {
      if (this.userType !== "PublicUser") {
        this.$router.push(this.$translate({
          name: `${this.userType}s`,
        }));
      } else {
        this.$router.push(this.$translate({
          name: "Public Users",
        }));
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
